import React, { useEffect, useState } from "react";

import { Button, Form, Input, notification } from "antd";

import { useSelector } from "react-redux";

import { selectwidgets } from "../../features/widgets/selector";

import { selectTranslation } from "../../features/Translation/TranslationSlice";

import TEXT from "../../config/text";

import REQUESTS from "../../api/requests";

import Categories from "./components/Categories";

import "./index.scss";

export default function ManageCategories() {
  const [liveTV, setLiveTV] = useState([]);

  const [liveIds, setLiveIds] = useState([]);
  
  const [movies, setMovies] = useState([]);

  const [moviesIds, setMoviesIds] = useState([]);
  
  const [shows, setShows] = useState([]);

  const [showsIds, setShowsIds] = useState([]);
  
  const [categories, setCategories] = useState([]);
  
  const [loading, steLoading] = useState(false);
  
  const translation = useSelector(selectTranslation);
  const widgets = useSelector(selectwidgets);

  const [form] = Form.useForm();

  const getCategories = () => {
    function callback(data) {
      setCategories(data);
      setLiveIds(data.live_tv);
      setMoviesIds(data.movies);
      setShowsIds(data.tv_shows);

      if (data.name && data.password && data.host) {
        getContentCategories(data);
      }
    }

    function erorCallback(err) {
      notification.open({
        message: "Manage categories",
        description: err,
        duration: 5,
      });
    }

    REQUESTS.CATEGORIES.GET(callback, erorCallback);
  };

  const onFinish = (values) => {
    const { name, password, host } = values;
    
    steLoading(true);

    let live_tv = liveTV.filter((item) => liveIds.includes(item.category_id));
    live_tv = live_tv.map((item) => item.category_id);

    let tv_shows = shows.filter((item) => showsIds.includes(item.category_id));
    tv_shows = tv_shows.map((item) => item.category_id);

    let moviesData = movies.filter((item) =>
      moviesIds.includes(item.category_id)
    );

    moviesData = moviesData.map((item) => item.category_id);

    REQUESTS.CATEGORIES.PUT(
      (data) => {
        steLoading(false);
        getCategories();

        notification.open({
          message: "Manage categories",
          description: data,
          duration: 5,
        });
      },
      (err) => {
        steLoading(false);
        notification.open({
          message: "Manage categories",
          description: err,
          duration: 5,
        });
      },
      {
        name,
        password,
        host,
        live_tv: live_tv,
        tv_shows: tv_shows,
        movies: moviesData,
      }
    );
  };

  const getContentCategories = (config) => {
    REQUESTS.CONTENT_CATEGORIES(
      (data) => {
        for (const property in data) {
          switch (property) {
            case "live_data":
              const livesData = fiterCategories(
                data["live_data"],
                config.live_tv
              );

              setLiveTV(livesData);
              break;

            case "movie_data":
              const moviesData = fiterCategories(
                data["movie_data"],
                config.movies
              );

              setMovies(moviesData);
              break;

            case "series_data":
              const seriesData = fiterCategories(
                data["series_data"],
                config.tv_shows
              );

              setShows(seriesData);
              break;

            default:
              break;
          }
        }
      },
      (err) => {
        notification.open({
          message: "Manage categories",
          description: err,
          duration: 5,
        });
      }
    );
  };

  const fiterCategories = (list = [], ids = []) => {
    let sortedList = [];

    for (let i = 0; i < ids.length; i++) {
      for (let g = 0; g < list.length; g++) {
        if (ids[i] === list[g].category_id) {
          sortedList.push(list[g]);
        }
      }
    }

    const all = list.filter((item) => !ids.includes(item.category_id));

    const newdata = [...sortedList, ...all];

    return newdata;
  };

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (categories) {
      form.setFields([
        {
          name: "name",
          value: categories.name,
        },

        {
          name: "password",
          value: categories.password,
        },

        {
          name: "host",
          value: categories.host,
        },
      ]);
    }
  }, [categories]);

  return (
    <div className="manage-categories-page">
      {widgets &&
        widgets.manage_xtream_categories &&
        widgets.manage_xtream_categories.enabled &&
        widgets.manage_xtream_categories.enabled === "false" && (
          <>
            <div className="pro-widget-warning-text">
              To use this feature you need to upgrade to Pro Version
              <br />
              Please contact us for more details.
            </div>
            <div className="pro-widget-tab-layer"></div>
          </>
        )}

      <h1 className="manage-categories-page-title">Manage categories</h1>
      <Form
        form={form}
        name="manage-playlist"
        layout="vertical"
        onFinish={onFinish}
      >
        <div className="manage-categories-form">
          <div className="manage-categories-form__items">
            <Form.Item
              label={translation["Username"] || TEXT["Username"]}
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input username",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={translation["Password"] || TEXT["Password"]}
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input password",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={translation["Host"] || TEXT["Host"]}
              name="host"
              rules={[
                {
                  required: true,
                  message: "Please input host",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className="manage-categories-form__btn-conatiner">
            <Form.Item>
              <Button
                type="primary"
                onClick={() => form.submit()}
                loading={loading}
              >
                {translation["Save"] || TEXT["Save"]}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>

      <Categories
        liveTV={liveTV}
        movies={movies}
        shows={shows}
        liveIds={liveIds}
        moviesIds={moviesIds}
        showsIds={showsIds}
        setShowsIds={setShowsIds}
        setLiveIds={setLiveIds}
        setMoviesIds={setMoviesIds}
        setLiveTV={setLiveTV}
        setShows={setShows}
        setMovies={setMovies}
      />
    </div>
  );
}
