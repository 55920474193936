import { useState, useRef, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { Outlet } from "react-router-dom";
import io from "socket.io-client";

import { Layout, notification, Drawer } from "antd";
import Sidbar from "./components/Sidebar";
import HeaderComponent from "./components/Header";

import {
  selectchat,
  editchat,
  selectActivId,
  selectMeesageCount,
  editMeesageCount,
} from "../features/chat/chatSlise";

import { selectappInfo } from "../features/appInfoSlice/AppInfoSlice";
import { selectwidgets } from "../features/widgets/selector";
import CONSTANTS from "../config";

import classes from "./index.module.scss";
import "../index.scss";
import { get_chat_list } from "../pages/realTimeChat/requests/realTimechatRequests";

const { Header, Sider, Content } = Layout;

const DashboardPage = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const socketRef = useRef(null);

  const dispatch = useDispatch();

  const newChat = useSelector(selectchat);
  const appInfo = useSelector(selectappInfo);
  const activeChat = useSelector(selectActivId);
  // const messageCount = useSelector(selectMeesageCount);
  const widgets = useSelector(selectwidgets);

  const [messageCount, setMessageCount] = useState(0);

  const [collapsed, setCollapsed] = useState(false);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const openNotification = () => {
    const args = {
      message: "Chat",
      description: "New message",
      duration: 5,
    };
    notification.open(args);
  };

  const getChatList = () => {
    const query = {
      limit: 10000000,
      filter: JSON.stringify({
        viewed: false,
        sender: "user",
      })
    }
    get_chat_list((data) => {
      dispatch(editMeesageCount(data.length));

      setMessageCount(data.length);

    }, query)
  }

  const connect = () => {

    if (socketRef && socketRef.current) return

    socketRef.current = io(CONSTANTS.API_HOST, {
      extraHeaders: {
        Authorization: `Bearer ${localStorage.getItem("TOKEN")}`,
        type: "admin",
      },
    });

    socketRef.current.on("message", (data) => {
      dispatch(editchat());
      getChatList()
    });
  };

  useEffect(() => {

    if (activeChat == "") {
      getChatList()
    }

    if (activeChat === "" && messageCount > 0) {
      openNotification()
    }

  }, [messageCount]);

  useEffect(() => {
    if (windowSize < 790) {
      setCollapsed(false);
    }
  }, [windowSize]);

  useEffect(() => {
    if (widgets?.chat?.enabled === "true") {
      connect();
    }
  }, [widgets])

  useEffect(() => {

    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);

      if (socketRef && socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  return (
    <Layout className="layout" style={{ minHeigh: "100vh" }}>
      {windowSize > 800 ? (
        <Sider
          style={{
            height: "100vh",
            overflowY: "auto",
            overflowX: "hidden",
            backgroundColor: appInfo.sidebar_background || "#1a1a1a",
          }}
          className="sidebar"
          trigger={null}
          collapsible
          collapsed={collapsed}
          width={220}
        >
          <div className={classes["logo-wrapper"]}>
            <div className={classes["logo"]}>
              <img
                src={appInfo?.logo || "assets/img/sidbarlogo.png"}
                onError={(e) => {
                  e.target.src = "assets/img/sidbarlogo.png";
                }}
                style={{ width: appInfo?.sidebar_logo_size || "100px" }}
                alt="logo"
              />
            </div>
          </div>
          <Sidbar style={{ height: "83vh" }} collapsed={collapsed} />
        </Sider>
      ) : (
        <Drawer
          placement="left"
          closable={false}
          onClose={() => setOpen(false)}
          open={open}
          title={null}
          bodyStyle={{ padding: 0 }}
          width={290}
        >
          <Sider
            style={{
              height: "100vh",
              overflowY: "auto",
              overflowX: "hidden",
              backgroundColor: appInfo.sidebar_background || "#1a1a1a",
            }}
            className="sidebar"
            trigger={null}
            collapsible
            width={220}
          >
            <div className={classes["logo-wrapper"]}>
              <div className={classes["logo"]}>
                <img
                  src={appInfo?.logo || "assets/img/sidbarlogo.png"}
                  onError={(e) => {
                    e.target.src = "assets/img/sidbarlogo.png";
                  }}
                  style={{ width: appInfo?.sidebar_logo_size || "100px" }}
                  alt="logo"
                />
              </div>
            </div>
            <Sidbar style={{ height: "83vh" }} collapsed={collapsed} setOpen={setOpen} />
          </Sider>
        </Drawer>
      )}

      <Layout className={classes["site-layout"]}>
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
            color: "black",
            height: "63px",
          }}
        >
          <HeaderComponent
            windowSize={windowSize}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            showDrawer={showDrawer}
          />
        </Header>
        <Content
          className={classes["site-layout-background"]}
          style={{
            minHeight: 280,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardPage;
