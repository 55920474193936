import { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { Switch } from "antd";

import { selectwidgets } from "../../features/widgets/selector";

import UsedDevicesProgres from "./components/UsedDevicesProgres";
import PaymentStatistics from "./components/PaymentStatistics";
import ServerStatistics from "./components/ServerStatistics";

import classes from "./index.module.scss";
import "./styles/chartStyle.scss";
import DashboardCards from "./components/DashboardCards";
import OnlineUsersStatistics from "./components/OnlineUsersStatistics";

const intervalTime = 60000;

function Dashboard() {
  const [checkAutoupadte, setCheckAutoupdate] = useState(true);
  const [getAgain, setGetAgain] = useState(false);
  const widgets = useSelector(selectwidgets);

  const onChange = (checked) => {
    setCheckAutoupdate(checked)
  };

  useEffect(() => {
    let id = null;

    if(checkAutoupadte) {
      id = setTimeout(() => {
        setGetAgain(!getAgain)
      }, intervalTime);
    }

    return () => clearTimeout(id);
  }, [checkAutoupadte]);

  return (
    <div>

      <div className={classes["dashboard-autorefresh"]}>
        <span>Autoupdate</span>
        <Switch checked={checkAutoupadte} onChange={onChange} />
      </div>

      <DashboardCards getAgain={getAgain} />

      <div className={classes["dashboard-statistics"]}>
        <div className={classes["used-device-progress"]}>
           <UsedDevicesProgres  getAgain={getAgain}/>
        </div>
        <ServerStatistics />
      </div>
      <div className={classes["statistics"]}>
        <PaymentStatistics getAgain={getAgain} />
        <OnlineUsersStatistics getAgain={getAgain} />
      </div>
  
    </div>
  );
}

export default Dashboard;
