import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { Table, Button, Modal } from "antd";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";

import TEXT from "../../config/text";
import REQUESTS from "../../api/requests";
import ICONS from "../../config/icons";
import NotificationDrawer from "./components/NotificationDrawer";
import { getColumnSearchProps } from "../../utils/index";

import { selectwidgets } from "../../features/widgets/selector";
import getMyDate from "../../components/getMyDate";
import getDate from "../../components/getDate";

import TableButtons from "../../components/TableButtons";

import "./index.scss";

export default function Notification() {
  const [notifications, setNotifications] = useState("");
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState(["id", "DESC"]);
  const [search, setSearch] = useState({});
  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState(null);
  const [type, setType] = useState("");
  const widgets = useSelector(selectwidgets);

  const translation = useSelector((state) => state.translationData.translation);

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: `${translation["Title"] || TEXT["Title"]}`,
      dataIndex: "title",
      key: "title",
      align: "center",
      ...getColumnSearchProps(),
    },
    {
      title: `${translation["Send to"] || TEXT["Send to"]}`,
      dataIndex: "filter",
      key: "filter",
      align: "center",
      filters: [
        {
          text: "Platform",
          value: "platform",
        },
        {
          text: "Mac",
          value: "mac",
        },
        {
          text: "Status",
          value: "status",
        },
      ],
      render: (text, record) => {
        if (record) {
          if (record.model) {
            return record.model;
          } else if (record.free_trial_expired) {
            return "Free trial expired";
          } else if (record.free_trial) {
            return "Free trial";
          } else if (record.mac) {
            return record.mac;
          } else {
            return "all";
          }
        }
      },
    },
    {
      title: `${translation["Total send"] || TEXT["Total send"]}`,
      dataIndex: "total",
      key: "total",
      align: "center",
      sorter: true,
    },
    {
      title: `${translation["Start date"] || TEXT["Start date"]}`,
      dataIndex: "sended_date",
      key: "sended_date",
      align: "center",
      sorter: true,
      render: (text, record) => {
        if (record && record.sended_date) {
          return getDate(record.sended_date)
        }else{
          return getDate(record.createdAt)
        }
      },
    },
    {
      title: `${translation["End date"] || TEXT["End date"]}`,
      dataIndex: "expired_date",
      key: "expired_date",
      align: "center",
      sorter: true,
      render: (text, record) => {
      
        if (record && record.expired_date){
          return getDate(record.expired_date);
        }else{
          return <div style={{textAlign:"center"}}>-</div>
        }
      },
    },
    {
      title: `${translation["Received"] || TEXT["Received"]}`,
      dataIndex: "viewed",
      key: "viewed",
      align: "center",
      sorter: true,
    },
    {
      title: `${translation["Status"] || TEXT["Status"]}`,
      dataIndex: "status",
      key: "status",
      align: "center",
      filters: [
        {
          text: "Active",
          value: "active",
        },
        {
          text: "Waiting",
          value: "waiting",
        },
        {
          text: "Expired",
          value: "expired",
        },
      ],
      filterMultiple:false,
    },
    {
      title: `${translation["Created date"] || TEXT["Created date"]}`,
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      sorter: true,
      render: (text, record) => {
        if (record && record.createdAt) return getMyDate(record.createdAt);
      },
    },
    {
      title: "",
      dataIndex: "config",
      align: "center",
      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          buttons={
            record && record.status === "waiting"
              ? [
                  {
                    key: "edit",
                    text: `${translation["Edit"] || TEXT["Edit"]}`,
                    icon: <EditOutlined />,
                  },
                  {
                    key: "delete",
                    text: `${translation["Delete"] || TEXT["Delete"]}`,
                    icon: <DeleteOutlined />,
                  },
                  {
                    key: "freeze",
                    text: record.freeze
                      ? `${translation["Unfreeze"] || TEXT["Unfreeze"]}`
                      : `${translation["Freeze"] || TEXT["Freeze"]}`,
                    icon: ICONS.FREEZE,
                  },
                  {
                    key: "repeat",
                    text: `${translation["Repeat"] || TEXT["Repeat"]}`,
                    icon: ICONS.REPEAT,
                  },
                ]
              : [
                  {
                    key: "delete",
                    text: `${translation["Delete"] || TEXT["Delete"]}`,
                    icon: <DeleteOutlined />,
                  },
                  {
                    key: "freeze",
                    text: record.freeze
                      ? `${translation["Unfreeze"] || TEXT["Unfreeze"]}`
                      : `${translation["Freeze"] || TEXT["Freeze"]}`,
                    icon: ICONS.FREEZE,
                  },
                  {
                    key: "repeat",
                    text: `${translation["Repeat"] || TEXT["Repeat"]}`,
                    icon: ICONS.REPEAT,
                  },
                ]
          }
        />
      ),
    },
  ];

  const getNotifications = () => {
    setLoading(true);
    const query = {
      page: currentPage,
      limit,
      pagination: 1,
      sort: JSON.stringify(sort),
    };

    if (search.title) {
      query.search = {};
      query.search["title"] = search.title[0];
      query.search = JSON.stringify(query.search);
    }

    if (search.filter) {
      search.filter.map((item) => {
        if (item === "platform") {
          query.model = true;
        } else if (item === "status") {
          query.free_trial = true;
          query.free_trial_expired = true;
        } else if (item === "mac") {
          query.mac = true;
        }
      });
    }

    if (search.status) {
      query.filter = {};
      search.status.map((item) => {
        if (item === "active") {
          query.filter.status = "active";
        } else if (item === "waiting") {
          query.filter.status = "waiting";
        } else if (item === "expired") {
          query.filter.status = "expired";
        }
      });
    }

    if (query.filter) {
      query.filter = JSON.stringify(query.filter);
    }

    REQUESTS.NOTIFICATIONS.GET(
      query,
      (data) => {
        setTotal(data.count);
        setLoading(false);
        setNotifications(data.rows);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const freezeNotification = (id) => {
    REQUESTS.NOTIFICATION_FREEZE(
      { id },
      (data) => {
        getNotifications();
      },
      (err) => {}
    );
  };

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "delete":
        Modal.confirm({
          content: "Are you sure you want to delete?",
          okType: "default",
          okButtonProps: {
            disabled: false,
            style: {
              backgroundColor: "#bf4342",
              color: "#fff",
            },
            loading: loading,
          },
          cancelText: "CANCEL",
          onOk() {
            deleteNotification(item.id);
          },
          onCancel() {},
        });
        break;

      case "edit":
        setVisible(true);
        setCurrent(item);
        setType("edit");
        break;

      case "freeze":
        Modal.confirm({
          content: !item.freeze
            ? `${
                translation["Are you sure you want to freeze?"] ||
                TEXT["Are you sure you want to freeze?"]
              }`
            : `${
                translation["Are you sure you want to unfreeze?"] ||
                TEXT["Are you sure you want to unfreeze?"]
              }`,
          okType: "default",
          okButtonProps: {
            disabled: false,
            style: {
              backgroundColor: "#bf4342",
              color: "#fff",
            },
            loading: loading,
          },
          cancelText: "CANCEL",
          onOk() {
            freezeNotification(item.id);
          },
          onCancel() {},
        });
        break;

      case "repeat":
        setType("repeat");
        setVisible(true);
        setCurrent(item);
        break;

      default:
        break;
    }
  };

  const deleteNotification = (id) => {
    REQUESTS.NOTIFICATIONS.DELETE(
      { id },
      (data) => {
        getNotifications();
      },
      (err) => {}
    );
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }

    setSearch(filters);

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      getNotifications();
    }, 500);

    return () => clearTimeout(timeout);
  }, [search, limit, sort, currentPage]);

  return (
    <div className="notification-page-container">
      {widgets &&
        widgets.notification &&
        widgets.notification.enabled &&
        widgets.notification.enabled === "false" && (
          <>
            <div className="pro-widget-warning-text">
              To use this feature you need to upgrade to Pro Version
              <br/>
              Please contact us for more details.
            </div>
            <div className="pro-widget-tab-layer"></div>
          </>
        )}
      <div>
        <h1 className="notification-page_title">My notification list</h1>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setVisible(true);
            setType("add");
            setCurrent(null);
          }}
          className="reseller-page_add-edit-btn"
        />

        <Table
          columns={columns}
          dataSource={notifications}
          rowClassName={(record) => record && record.freeze && "freeze"}
          loading={loading}
          onChange={handleTableChange}
          pagination={{
            position: ["bottomCenter"],
            current: currentPage,
            total: total,
            pageSize: limit,
            showSizeChanger: true,
          }}
          scroll={{ x: "max-content" }}
          size="small"
        />

        <NotificationDrawer
          visible={visible}
          onClose={() => setVisible(false)}
          getNotifications={getNotifications}
          current={current}
          type={type}
        />
      </div>
    </div>
  );
}
