import ChatList from "./ChatList";

import SearchChat from "./SearchChat";

import classes from "../../style/chatBar.module.scss";

function ChatBar({ setIsShow, setInputValue }) {
  return (
    <div className={classes["chatBar_container"]}>
      <SearchChat />
      <ChatList setIsShow={setIsShow}
        setInputValue={setInputValue} />
    </div>
  );
}

export default ChatBar;
