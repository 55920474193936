import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Form,
  Input,
  Drawer,
  Select,
  DatePicker,
  Checkbox,
} from "antd";

import moment from "moment";

import REQUESTS from "../../../api/requests";
import TEXT from "../../../config/text";
import ErrorMessage from "../../../components/ErrorMessage";

import "../index.scss";
import MacMaskComponent from "../../../components/MacMaskComponent";

export default function NotificationDrawer({
  onClose,
  visible,
  current,
  getNotifications,
  type,
}) {
  const [loading, setLoading] = useState();
  const [selectedValue, setSelectedValue] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [showStartDate, setShowStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showEndDate, setShowEndDate] = useState(null);
  const [status, setStatus] = useState([]);
  const [model, setModel] = useState();
  const [message, setMessage] = useState({
    type: false,
    text: "",
  });

  const [macValue, setMacValue] = useState("");

  const translation = useSelector((state) => state.translationData.translation);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const body = {
      title: values.title,
      desc: values.desc,
    };

    if (endDate) {
      body["expired_date"] = new Date(endDate);
    }

    if (startDate) {
      body["sended_date"] = new Date(startDate);
    }

    if (selectedValue === "all") {
      body["all"] = true;
    } else if (selectedValue === "mac") {
      body["mac"] = macValue;
    } else if (selectedValue === "platforms") {
      if (model) {
        body["model"] = model;
      }
    } else if (selectedValue === "status") {
      if (status) {
        body[status] = true;
      }
    }

    if (type === "add" || type === "repeat") {
      REQUESTS.NOTIFICATIONS.ADD(
        body,
        (data) => {
          getNotifications();
          onClose();
          setLoading(false);
        },
        (err) => {
          setLoading(false);

          setMessage({
            type: false,
            text: err,
          });
        }
      );
    } else {
      if (current) {
        body.id = current.id;
        REQUESTS.NOTIFICATIONS.EDIT(
          body,
          (data) => {
            setLoading(false);

            getNotifications();
            onClose();
          },
          (err) => {
            setLoading(false);

            setMessage({
              type: false,
              text: err,
            });
          }
        );
      }
    }
  };

  useEffect(() => {
    if (visible && current && (type === "edit" || type === "repeat")) {

      form.setFields([
        {
          name: "desc",
          value: current.desc,
        },
        {
          name: "title",
          value: current.title,
        },
      ]);

      if (current.sended_date) {
        setShowStartDate(true);
        const date = new Date(current.sended_date);
        setStartDate(date);
      }

      if (current.expired_date) {
        setShowEndDate(true);
        const date = new Date(current.expired_date);

        setEndDate(date);
      }

      if (current.mac) {
        setSelectedValue("mac");
        setMacValue(current.mac)
      } else if (current.model) {
        setSelectedValue("platforms");
        setModel(current.model);
      } else if (current.free_trial) {
        setSelectedValue("status");
        setStatus(["free_trial"]);
      } else if (current.free_trial_expired) {
        setSelectedValue("status");
        setStatus(["free_trial_expired"]);
      } else {
        setSelectedValue("all");
      }
    }
  }, [current, visible, type]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setSelectedValue("");
      setEndDate(null);
      setStartDate(null);
      if (showStartDate) {
        setShowStartDate(false);
      }
      if (showEndDate) {
        setShowEndDate(false);
      }
      setMessage({
        type: false,
        text: "",
      });
    }
  }, [visible]);

  useEffect(() => {
    if (type === 'add' && selectedValue !== 'mac' && macValue) {
      setMacValue("")
    }
  }, [selectedValue]);

  return (
    <div>
      <Drawer
        title={
          type === "edit"
            ? translation["Edit notification"] || TEXT["Edit notification"]
            : type === "add"
              ? translation["Add notification"] || TEXT["Add notification"]
              : translation["Repeat notification"] || TEXT["Repeat notification"]
        }
        placement="right"
        onClose={onClose}
        open={visible}
      >
        <Form
          form={form}
          name="profile"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{
            password: "",
          }}
        >
          <Form.Item
            label={translation["Title"] || TEXT["Title"]}
            name="title"
            rules={[
              {
                required: true,
                message: "Please input title",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={translation["Message"] || TEXT["Message"]}
            name="desc"
            rules={[
              {
                required: true,
                message: "Please input message",
              },
            ]}
          >
            <Input.TextArea rows={4} resize="none" />
          </Form.Item>

          <Form.Item label="Devices by">
            <Select
              placeholder="Devices by"
              value={selectedValue}
              onChange={(value) => setSelectedValue(value)}
            >
              <Select.Option key={`All`} value={`all`}>
                All
              </Select.Option>
              <Select.Option key={`Platforms`} value={`platforms`}>
                Platforms
              </Select.Option>
              <Select.Option key={`Mac`} value={`mac`}>
                Mac
              </Select.Option>
              <Select.Option key={`Status`} value={`status`}>
                Status
              </Select.Option>
            </Select>
          </Form.Item>

          {(() => {
            switch (selectedValue) {
              case "platforms":
                return (
                  <Form.Item>
                    <Select
                      defaultValue={model}
                      value={model}
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      onChange={(value) => {
                        setModel(value);
                      }}
                      options={[
                        { label: "Tizen", value: "tizen" },
                        { label: "Webos", value: "webos" },
                      ]}
                    />
                  </Form.Item>

                );
              case "mac":
                return (
                  <MacMaskComponent value={macValue} setValue={setMacValue} />
                );
              case "status":
                return (
                  <Form.Item>

                    <Select
                      defaultValue={status}
                      allowClear
                      style={{
                        width: "100%",
                      }}
                      onChange={(value) => {
                        setStatus(value);
                      }}
                      value={status}
                      options={[
                        { label: "Free trial", value: "free_trial" },
                        { label: "Free trial expired", value: "free_trial_expired", },
                      ]}
                    />
                  </Form.Item>

                );
              default:
                break;
            }
          })()}

          <div>
            <Checkbox
              checked={showStartDate}
              onChange={(event) => {
                if (!event.target.checked) {
                  setStartDate(null)
                }

                setShowStartDate(!showStartDate);
              }}
            >
              Start date
            </Checkbox>
            {showStartDate && (
              <DatePicker
                format="YYYY-MM-DD"
                onChange={(date, dateString) => {
                  setStartDate(dateString);
                }}
                value={startDate && startDate !== "" ? moment(startDate) : ""}
                disabledDate={(current) => {
                  let customDate = moment().format("YYYY-MM-DD");
                  return current && current < moment(customDate, "YYYY-MM-DD");
                }}
                placeholder="Start date"
                className="notification-drawer_datepicker"
              />
            )}
          </div>
          <div>
            <Checkbox
              checked={showEndDate}
              onChange={(event) => {
                if (!event.target.checked) {
                  setEndDate(null)
                }
                setShowEndDate(!showEndDate);
              }}
            >
              End date
            </Checkbox>

            {showEndDate && (
              <DatePicker
                placeholder="End date"
                className="notification-drawer_datepicker"
                format="YYYY-MM-DD"
                onChange={(date, dateString) => {
                  setEndDate(dateString);
                }}
                disabledDate={(current) => {
                  let customDate = moment().format("YYYY-MM-DD");
                  return (
                    current &&
                    current < moment(customDate, "YYYY-MM-DD")
                  );
                }}
                value={endDate && endDate !== "" ? moment(endDate) : ""}
              />
            )}
          </div>
          <ErrorMessage message={message} />

          <div className="notification-drawer__save-button">
            <Form.Item>
              <Button
                type="primary"
                onClick={() => {
                  form.submit();
                  setLoading(true);
                  setMessage({
                    type: true,
                    text: "",
                  });
                }}
                loading={loading}
              >
                {translation["Save"] || TEXT["Save"]}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Drawer>
    </div>
  );
}
