import { useSelector, useDispatch } from "react-redux";
import {
  editActivId,
  selectActivId,
} from "../../../../features/chat/chatSlise";

import classes from "../../style/chatList.module.scss";

function RenderChatLis(chatList, setIsShow, setInputValue) {
  const activeChatId = useSelector(selectActivId);
  const dispatch = useDispatch();

  return <div className={classes["list"]}>
    {chatList.map((el) => {
      return (
        <div
          key={el.id}
          className={
            classes[
            activeChatId.id !== el.id
              ? "chat_items_container"
              : "chat_items_container_active"
            ]
          }
          onClick={() => {
            dispatch(editActivId(el));
            setInputValue("");
          }}
        >
          <div
            className={
              classes[
              el.sender === "user" && el.viewed === false
                ? "new_chat_indicator"
                : "unanswered_chat_indicator"
              ]
            }
          ></div>
          <div
            className={classes["chat_list_item"]}
            onClick={() => setIsShow(true)}
          >
            <p className={classes["chat_list_mac"]}>{el.mac}</p>
            <p className={classes["chat_last_message"]}>{el.last_message}</p>
          </div>
        </div>
      );
    })}
  </div>
}

export default RenderChatLis;
