export default  function getDate (date) {
    const dateObj = new Date(date);
    let year = dateObj.getFullYear();
    let day = dateObj.getDate();
    let month = dateObj.getMonth() + 1;
  
    year = year < 10 ? "0" + year : year;
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;
  
    return `${day}/${month}/${year}`;
  }
  