import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import {
  DashboardOutlined,
  DesktopOutlined,
  HistoryOutlined,
  LockOutlined,
  ShoppingOutlined,
  SettingOutlined,
  AliwangwangOutlined,
  MailOutlined,
  WechatOutlined,
  ContainerOutlined,
} from "@ant-design/icons";

import REQUESTS from "../../api/requests";

import TEXT from "../../config/text";
import ICONS from "../../config/icons";

import { selectTranslation } from "../../features/Translation/TranslationSlice";
import { selectMeesageCount } from "../../features/chat/chatSlise";
import { selectwidgets } from "../../features/widgets/selector";

import classes from "../style/sidbar.module.scss";
import "../../index.scss";

const VERSION =require('../../../package.json').version

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

function Sidbar({ collapsed, setOpen }) {
  const translation = useSelector(selectTranslation);
  const meesageCount = useSelector(selectMeesageCount);
  const widgets = useSelector(selectwidgets);

  const [apiVersion, setApiVersion] = useState("");

  const location = useLocation();
  const [current, setCurrent] = useState(location.pathname);

  const items = [
    getItem(
      <Link className={classes["menu_item_link"]} to={"./"}>
        {translation["Dashboard"] || TEXT["Dashboard"]}
      </Link>,
      "/DashboardPage",
      <DashboardOutlined />
    ),
    getItem(
      <Link className={classes["menu_item_link"]} to={"./deviceManager"}>
        {translation["Devices"] || TEXT["Devices"]}
      </Link>,
      "/DashboardPage/deviceManager",
      <DesktopOutlined />
    ),
    getItem(
      <Link className={classes["menu_item_link"]} to={"./paymentHistory"}>
        {translation["Payments"] || TEXT["Payments"]}
      </Link>,
      "/DashboardPage/paymentHistory",
      <HistoryOutlined />
    ),
    getItem(
      <Link className={classes["menu_item_link"]} to={"./userPackages"}>
        {translation["Pricing"] || TEXT["Pricing"]}
      </Link>,
      "/DashboardPage/userPackages",
      <ShoppingOutlined />
    ),
    getItem(
      <Link className={classes["menu_item_link"]} to={"./blacklist"}>
        {translation["Blacklist"] || TEXT["Blacklist"]}
      </Link>,
      "/DashboardPage/blacklist",
      <LockOutlined />
    ),
    getItem(
      <div className="label-icon-container">
        <Link className={classes["menu_item_link"]} to={"./referral"}>
          {translation["Referral"] || TEXT["Referral"]}
        </Link>
        <div> {ICONS.PRO}</div>
      </div>,
      "/DashboardPage/referral",
      ICONS.REFERRAL,
      [
        getItem(
          <Link
            className={classes["menu_item_link"]}
            to={
              widgets?.referral?.enabled === "true"
                ? "/DashboardPage/referralwithdraw"
                : "/DashboardPage/referral"
            }
          >
            {translation["Withdraw requests"] || TEXT["Withdraw requests"]}
          </Link>,
          "/DashboardPage/referralwithdraw"
        ),
        getItem(
          <Link
            className={classes["menu_item_link"]}
            to={
              widgets?.referral?.enabled === "true"
                ? "./referralEarnings"
                : "/DashboardPage/referral"
            }
          >
            {translation["Earnings"] || TEXT["Earnings"]}
          </Link>,
          "/DashboardPage/referralEarnings"
        ),
        getItem(
          <Link
            className={classes["menu_item_link"]}
            to={
              widgets?.referral?.enabled === "true"
                ? "./referralLinks"
                : "/DashboardPage/referral"
            }
          >
            {translation["Links"] || TEXT["Links"]}
          </Link>,
          "/DashboardPage/referralLinks"
        ),
        getItem(
          <Link
            className={classes["menu_item_link"]}
            to={
              widgets?.referral?.enabled === "true"
                ? "./referralStatuses"
                : "/DashboardPage/referral"
            }
          >
            {translation["Statuses"] || TEXT["Statuses"]}
          </Link>,
          "/DashboardPage/referralStatuses"
        ),
      ]
    ),
    getItem(
      <div className="label-icon-container">
        <Link className={classes["menu_item_link"]} to={"./reseller"}>
          {translation["Reseller"] || TEXT["Reseller"]}
        </Link>
        <div> {ICONS.PRO}</div>
      </div>,
      "/DashboardPage/reseller",
      ICONS.RESELLER,
      [
        getItem(
          <Link
            className={classes["menu_item_link"]}
            to={
              widgets?.reseller?.enabled === "true"
                ? "./referralpackages"
                : "/DashboardPage/reseller"
            }
          >
            {translation["Credits"] || TEXT["Credits"]}
          </Link>,
          "/DashboardPage/referralpackages"
        ),
        getItem(
          <Link
            className={classes["menu_item_link"]}
            to={
              widgets?.reseller?.enabled === "true"
                ? "./referralactivation"
                : "/DashboardPage/reseller"
            }
          >
            {translation["Reseller Credit"] || TEXT["Reseller Credit"]}
          </Link>,
          "/DashboardPage/referralactivation"
        ),
      ]
    ),
    getItem(
      <div className="label-icon-container">
        <div>
          <Link className={classes["menu_item_link"]} to={"./newsPage"}>
            {translation["News"] || TEXT["News"]}
          </Link>
        </div>
        <div>{ICONS.PRO}</div>
      </div>,
      "/DashboardPage/newsPage",
      <AliwangwangOutlined />
    ),
    getItem(
      <div className="label-icon-container">
        <Link className={classes["menu_item_link"]} to={"./chat"}>
          <div className={classes["messagesCount_container"]}>
            {translation["Chat"] || TEXT["Chat"]}{" "}
            {meesageCount ? (
              <div className={classes["messagesCount"]}>{meesageCount}</div>
            ) : (
              ""
            )}
          </div>
        </Link>
        <div className="pro-icon">{ICONS.PRO}</div>
      </div>,
      "/DashboardPage/chat",
      <WechatOutlined />
    ),
    getItem(
      <div className="label-icon-container">
        <Link className={classes["menu_item_link"]} to={"./support"}>
          {translation["Support"] || TEXT["Support"]}
        </Link>
        <div>{ICONS.PRO}</div>
      </div>,
      "/DashboardPage/support",
      <MailOutlined />
    ),
    getItem(
      <Link className={classes["menu_item_link"]} to={"./settings"}>
        {translation["Settings"] || TEXT["Settings"]}
      </Link>,
      "/DashboardPage/settings",
      <SettingOutlined />
    ),
    getItem(
      <div className="label-icon-container">
        <Link className={classes["menu_item_link"]} to={"./GIFTCode"}>
          {translation["Gift Codes"] || TEXT["Gift Codes"]}
        </Link>
        <div>{ICONS.PRO}</div>
      </div>,
      "/DashboardPage/coupons",
      <ContainerOutlined />
    ),
    getItem(
      <div className="label-icon-container">
        <Link className={classes["menu_item_link"]} to={"notification"}>
          {translation["Notification"] || TEXT["Notification"]}
        </Link>
        <div>{ICONS.PRO}</div>
      </div>,
      "/DashboardPage/notification",
      <span>
        {ICONS.NOTIFICATION}
      </span>
    ),
  ];

  const getApiVersion = () => {
    REQUESTS.GET_API_VERSION((data) => {
      setApiVersion(data)
    });
  };

  useEffect(() => {
    getApiVersion();
  }, []);
  return (
    <div className={classes["sidebar"]}>
      <Menu
        selectedKeys={[current]}
        mode="inline"
        inlineCollapsed={collapsed}
        items={items}
        style={{
          paddingTop: 0,
          fontSize: 15,
          minHeight: "78vh",
          backgroundColor: "transparent",
        }}
        onClick={(e) => {
          setCurrent(e.key);
          if (setOpen) {
            setOpen(false);
          }
        }}
      />
      <div
        className={classes["sidebar-footer-section"]}
        style={{
          fontSize: 10,
          marginLeft: 10,
        }}
      >
        <span>API {apiVersion}</span>
        <p>Admin v{VERSION}</p>
      </div>
    </div>
  );
}

export default Sidbar;
