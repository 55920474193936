import DndContextComonent from "./DndContextComonent";


export default function Categories({
  liveTV,
  movies,
  shows,
  setLiveIds,
  setMoviesIds,
  setShowsIds,
  liveIds,
  moviesIds,
  showsIds,
  setLiveTV,
  setShows,
  setMovies,
}) {
  return (
    <div>
      <h1>Xtream Categories</h1>
      <div className="xtream-palylists-categories">
        <div>
          <h1>Live TV</h1>
          <DndContextComonent
            setCategory={setLiveTV}
            category={liveTV}
            setCategoryIds={setLiveIds}
            categoryIds={liveIds}
          />
          
        </div>

        <div>
          <h1>Movies</h1>
          <DndContextComonent
            setCategory={setMovies}
            category={movies}
            setCategoryIds={setMoviesIds}
            categoryIds={moviesIds}
          />
        </div>

        <div>
          <h1>TV Shows</h1>
          <DndContextComonent
            setCategory={setShows}
            category={shows}
            setCategoryIds={setShowsIds}
            categoryIds={showsIds}
          />
        </div>
      </div>
    </div>
  );
}
