import REQUESTS from "../../../api/requests";

export function get_chat_list(state, query, setCount) {

  const colback = (data) => {
    state(data.rows);

   if(setCount){
    setCount(data.count)
   }
  };

  REQUESTS.CHAT.GET(query, colback);
}

export function get_messages(state, query) {

  const colback = (data) => {
    state(data.rows.reverse());
  };

  REQUESTS.CHAT.GET_MESSAGES(query, colback);
}

export function Send_message(body, get, setInputValue) {
  const colback = () => {
    get();
    setInputValue("");
  };

  const errorCallback = (err) => {};

  REQUESTS.CHAT.SEND(body, colback, errorCallback);
}

export function view_message_status(id, get) {
  const colback = () => {
    // get();
  };

  REQUESTS.CHAT.WIEWED(id, colback);
}
