import React, { useState, useEffect } from "react";
import { Drawer } from "antd";
import { useSelector } from "react-redux";
import { selectTranslation } from "../../../features/Translation/TranslationSlice";

import TEXT from "../../../config/text";
import ResellerActivationHistory from "./ResellerActivationHistory";
import ResellerSubresellers from "./ResellerSubresellers";

export default function ResellerInfoDrawer({ visible, onClose, current }) {
  const translation = useSelector(selectTranslation);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const changeWindowWidth = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", changeWindowWidth);

    return () => window.removeEventListener("resize", changeWindowWidth);
  }, []);

  return (
    <Drawer
      title={translation["Reseller info"] || TEXT["Reseller info"]}
      placement="right"
      onClose={onClose}
      open={visible}
      width={windowWidth > 880 ? 800 : "83vw"}
    >
      <div>
        {current && (
          <ul className="reseller-info-drawer-list">
            <li>
              {translation["Reseller name"] || TEXT["Reseller name"]} :{" "}
              {current.name}
            </li>
            <li>
              {translation["Reseller email"] || TEXT["Reseller email"]} :{" "}
              {current.email}
            </li>
            <li>
              {translation["Total Activations"] || TEXT["Total Activations"]} :{" "}
              {current.total_activations}
            </li>
            <li>
              {translation["Activated devices"] || TEXT["Activated devices"]} :{" "}
              {current.devices && current.devices.length}
            </li>
            <li>{translation["IP address"] || TEXT["IP address"]} :{" "}{current.ip}</li> 
            <li>
              {translation["Subresellers count"] || TEXT["Subresellers count"]}{" "}
              : {current.subresellers_count}
            </li>
          </ul>
        )}
      </div>

      <ResellerActivationHistory current={current} />
      <ResellerSubresellers current={current} />
    </Drawer>
  );
}
