import REQUESTS from "../../api/requests";
import { useEffect, useState, useContext } from "react";
import { emptyContext } from "../../context_empty/context_empty";
import { Table, Button, Input, Modal } from "antd";
import TableButtons from "../../components/TableButtons";
import classes from "./index.module.scss";
import { PlusOutlined, SearchOutlined, UserOutlined,MinusOutlined } from "@ant-design/icons";
import ResRefDrawer from "./components/ResRefDrawer";
import MyIcon from "../../components/myicon";
import ICONS from "../../config/icons";
import { useSelector } from "react-redux";
import showPropsConfirm from "../../components/showPropsConfirm";
import { selectTranslation } from "../../features/Translation/TranslationSlice";
import InitialParams from "../../components/InitialParams";
import { createSearchParams, useNavigate } from "react-router-dom";
import TEXT from "../../config/text";
import AddActivationDrawer from "./components/AddActivationDrawer";
const { confirm } = Modal;

function ReferralReseller() {
  const [empty, setEmpty] = useContext(emptyContext);
  const [total, setTotal] = useState();
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [addActivationDrawer, setAddActivationDrawer] = useState(false);
  const [selectedItem,setSelectedItem]=useState(null)
  const [editableReseller, setEditableReseller] = useState(null);
  const [referralsResellers, setReferralsResellers] = useState([]);
  const [sort, setSort] = useState(["id", "DESC"]);
  const [search, setSearch] = useState({
    comment: "",
  });

  InitialParams(setLimit, setCurrentPage, setSearch, setSort);

  const navigate = useNavigate();
  const goToPosts = (params) =>
    navigate({
      search: `?${createSearchParams(params)}`,
    });

  const translation = useSelector(selectTranslation);

  const getColumnSearchProps = () => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
      return (
        <Input
          allowClear
          autoFocus
          placeholder="Type text here"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
        />
      );
    },
    filterIcon: () => {
      return (
        <>
          <SearchOutlined />
        </>
      );
    },
  });

  const disableReseller = (item) => {
    const callback = () => {
      getResellers();
    };

    const errorCallback = (data) => {};

    REQUESTS.REFERRAL_RESELLER.DISABLE(item.id, callback, errorCallback);
  };

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "disable":
        showPropsConfirm(
          item,
          disableReseller,
          loading,
          `${translation["OK"] || TEXT["OK"]}`,
          "#bf4342",
          item.is_disabled
            ? `${
                translation["Are you sure you want to enable?"] ||
                TEXT["Are you sure you want to enable?"]
              }`
            : `${
                translation["Are you sure you want to disable?"] ||
                TEXT["Are you sure you want to disable?"]
              }`,
          `${translation["CANCEL"] || TEXT["CANCEL"]}`
        );
        break;

      case "edit":
        setEditableReseller(item);
        setVisible(true);
        break;
      case "Add activations":
        setAddActivationDrawer(true);
        setSelectedItem(item)
        break;

      default:
        break;
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      align: "center",
      render: (text, record, index) => {
        if (currentPage === 1) {
          return index + 1;
        } else {
          return limit * (currentPage - 1) + index + 1;
        }
      },
    },

    {
      title: `${translation["Name"] || TEXT["Name"]}`,
      dataIndex: "name",
      key: "name",
      align: "center",
      ...getColumnSearchProps("name"),
    },

    {
      title: `${translation["Surname"] || TEXT["Surname"]}`,
      dataIndex: "surname",
      key: "surname",
      align: "center",
      ...getColumnSearchProps(),
    },
    {
      title: `${translation["Email"] || TEXT["Email"]}`,
      dataIndex: "email",
      key: "email",
      align: "center",
      ...getColumnSearchProps("email"),
    },
    {
      title: `${translation["Phone"] || TEXT["Phone"]}`,
      dataIndex: "phone",
      align: "center",
      ...getColumnSearchProps(),
    },
    {
      title: `${translation["Country"] || TEXT["Country"]}`,
      dataIndex: "country",
      align: "center",
      ...getColumnSearchProps(),
    },

    {
      title: `${translation["Address"] || TEXT["Address"]}`,
      dataIndex: "address",
      align: "center",
      ...getColumnSearchProps(),
    },

    {
      title: `${
        translation["Referral earnings total"] ||
        TEXT["Referral earnings total"]
      }`,
      dataIndex: "referral_earnings_total",
      key: "referral_earnings_total",
      sorter: true,
      align: "center",
    },

    {
      title: `${translation["Total activations"] || TEXT["Total activations"]}`,
      dataIndex: "total_activations",
      key: "total_activations",
      sorter: true,
      align: "center",
    },
    {
      title: `${translation["Activation count"] || TEXT["Activation count"]}`,
      dataIndex: "activation_count",
      key: "activation_count",
      sorter: true,
      align: "center",
    },
    {
      title: `${translation["Balance"] || TEXT["Balance"]}`,
      dataIndex: "balance",
      key: "balance",
      sorter: true,
      align: "center",
    },
    {
      title: `${translation["IP address"] || TEXT["IP address"]}`,
      dataIndex: "ip",
      key: "ip",
      align: "center",
      ...getColumnSearchProps(),
    },

    {
      title: `${translation["Referral"] || TEXT["Referral"]}`,
      dataIndex: "is_referral",
      key: "is_referral",
      sorter: true,
      align: "center",
      render: (text, record, index) => {
        return referralsResellers[index].is_referral ? (
          <MyIcon children={ICONS.CHECK} />
        ) : (
          <MyIcon children={ICONS.TIMES} />
        );
      },
    },

    {
      title: `${translation["Reseller"] || TEXT["Reseller"]}`,
      dataIndex: "is_reseller",
      key: "is_reseller",
      sorter: true,
      align: "center",
      render: (text, record, index) => {
        return referralsResellers[index].is_reseller ? (
          <MyIcon children={ICONS.CHECK} />
        ) : (
          <MyIcon children={ICONS.TIMES} />
        );
      },
    },

    {
      title: "",
      dataIndex: "is_disabled",
      align: "center",
      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          buttons={[
            {
              key: "edit",
              text: `${translation["Edit"] || TEXT["Edit"]}`,
              icon: (
                <div>
                  <MyIcon children={ICONS.PEN} />
                </div>
              ),
            },
            {
              key: "disable",
              text: referralsResellers[index].is_disabled
                ? `${translation["Enable"] || TEXT["Enable"]}`
                : `${translation["Desible"] || TEXT["Desible"]}`,
              icon: referralsResellers[index].is_disabled ? (
                <div>
                  <MyIcon children={ICONS.Lock} />
                </div>
              ) : (
                <div>
                  <MyIcon children={ICONS.PADLOCK} />
                </div>
              ),
            },
            {
              key: "Add activations",
              text: translation["Add activations"] || TEXT["Add activations"],
              icon: <PlusOutlined />,
            },
            {
              key: "Take activations",
              text: translation["Take activations"] || TEXT["Take activations"],
              icon: <MinusOutlined />,
            },
          ]}
        />
      ),
    },
  ];

  const getResellers = () => {
    setLoading(true);
    const query = {
      page: currentPage,
      limit,
      pagination: 1,
      sort: JSON.stringify(sort),
      search: {},
    };

    if (search.name) {
      query.search["name"] = search.name[0];
    }
    if (search.surname) {
      query.search["surname"] = search.surname[0];
    }

    if (search.email) {
      query.search["email"] = search.email[0];
    }

    if (search.country) {
      query.search["country"] = search.country[0];
    }

    if (search.ip) {
      query.search["ip"] = search.ip[0];
    }

    if (search.phone) {
      query.search["phone"] = search.phone[0];
    }

    if (search.address) {
      query.search["address"] = search.address[0];
    }

    if (query.search) {
      query.search = JSON.stringify(query.search);
    }

    goToPosts(query);

    REQUESTS.REFERRAL_RESELLER.GET(query, (data) => {
      setTotal(data.count);
      // setLimit(data.limit);
      setLoading(false);
      setReferralsResellers(data.rows);

      if (!data.rows == []) {
        setEmpty(false);
      }
    });
  };
  
  useEffect(() => {
    return () => {
      setEmpty(true);
    };
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    // if (sorter.field) {
    //   //   setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    // }
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    setSearch(filters);

    if (Object.values(filters)[0]) {
      //   setTitleSearch(Object.values(filters)[0].join(""));
    } else {
      //   setTitleSearch(null);
    }

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const onClickAdd = () => {
    setVisible(true);
  };

  const closeDrawer = () => {
    setVisible(false);
    setEditableReseller(null);
  };

  useEffect(() => {
    let timout = setTimeout(() => {
      getResellers();
    }, 500);
    return () => {
      clearTimeout(timout);
    };
  }, [search, currentPage, limit, sort]);

  const followAddElement = {
    setCurrentPage,
    setSearch,
    setSort,
    setCurrentPage,
    setLimit,
    setTotal,
  };

  return (
    <div className={classes["ref_res_list_table"]}>
      <div className={classes["ref_res_list_title"]}>
        <p className={classes["ref_res_title-text"]}>
        
          {translation["Referral/Reseller"] || TEXT["Referral/Reseller"]}
        </p>
        <Button type="primary" icon={<PlusOutlined />} onClick={onClickAdd} />
      </div>

      <Table
        loading={loading}
        rowKey="id"
        rowClassName={(record, index) => {
          let className = referralsResellers[index].is_disabled
            ? classes["is_disabled"]
            : classes["no_disabled"];
          return className;
        }}
        columns={columns}
        dataSource={referralsResellers}
        onChange={handleTableChange}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
          // defaultPageSize: 1,
        }}
        scroll={{ x: "max-content" }}
      />

      <ResRefDrawer
        visible={visible}
        onClose={closeDrawer}
        current={editableReseller}
        getData={getResellers}
        followAddElement={followAddElement}
      />

      <AddActivationDrawer
        visible={addActivationDrawer}
        setAddActivationDrawer ={setAddActivationDrawer}
        getResellers={getResellers}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
    </div>
  );
}

export default ReferralReseller;
