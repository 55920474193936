import { useSelector } from "react-redux";
import { selectwidgets } from "../../features/widgets/selector";

import NewsTable from "./components/NewsTable";
import classes from "./index.module.scss";

function NewsPage() {
  const widgets = useSelector(selectwidgets);

  return (
    <div className={classes["nwes-page-container"]} >
      {((widgets && !widgets.news) ||
        (widgets && widgets.news &&
          widgets.news.enabled &&
          widgets.news.enabled === "false")) && (
        <>
          <div className="pro-widget-warning-text">
            To use this feature you need to upgrade to Pro Version
            <br />
            Please contact us for more details.
          </div>
          <div className="pro-widget-tab-layer"></div>
        </>
      )}
      <NewsTable />
    </div>
  );
}

export default NewsPage;
