export function ChatReducer(state = {}, action) {
  if (action.type === "add_chat") {
    return {
      ...state,
      chat: !state.chat,
    };
  } else if (action.type === "select_id") {
    return {
      ...state,
      activeId: action.payload.activeId,
    };
  } else if (action.type === "select_list") {
    return {
      ...state,
      chatList: action.payload.chatList,
    };
  } else if (action.type === "select_meesageCount") {
    return {
      ...state,
      meesageCount: action.payload.meesageCount,
    };
  }

  return state;
}

export const initialchat = {
  chat: false,
  activeId: "",
  chatList: [],
  meesageCount: 0,
};

export function selectchat(state) {
  return state.chatData.chat;
}

export function editchat() {
  return {
    type: "add_chat",
  };
}

export function selectActivId(state) {
  return state.chatData.activeId;
}

export function editActivId(id) {
  return {
    type: "select_id",
    payload: {
      activeId: id,
    },
  };
}

export function selectChatList(state) {
  return state.chatData.chatList;
}

export function editChatList(chat) {
  return {
    type: "select_list",
    payload: {
      chatList: chat,
    },
  };
}

export function selectMeesageCount(state) {
  return state.chatData.meesageCount;
}

export function editMeesageCount(count) {
  return {
    type: "select_meesageCount",
    payload: {
      meesageCount: count,
    },
  };
}
