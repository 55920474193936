import REQUESTS from "../../api/requests";
import { useEffect, useState, useContext } from "react";
import { emptyContext } from "../../context_empty/context_empty";
import { Table, Button, Input, Modal, DatePicker, Switch, Typography } from "antd";
import TableButtons from "../../components/TableButtons";
import classes from "./index.module.scss";
import ServerDrawer from "./components/ServerDrawer";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import getMyDate from "../../components/getMyDate";
import TEXT from "../../config/text";
import { useSelector } from "react-redux";
import { selectTranslation } from "../../features/Translation/TranslationSlice";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
const { confirm } = Modal;
const { RangePicker } = DatePicker;

function Server() {
  const [empty, setEmpty] = useContext(emptyContext);
  const [searchParams] = useSearchParams();
  const [total, setTotal] = useState();
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [editServer, setEditServer] = useState(null);
  const [server, setServer] = useState([]);
  const [date, setDate] = useState("");
  const [sort, setSort] = useState(["id", "DESC"]);
  const [search, setSearch] = useState({
    comment: "",
  });

  const translation = useSelector(selectTranslation);

  const navigate = useNavigate();
  const goToPosts = (params) =>
    navigate({
      search: `?${createSearchParams(params)}`,
    });

  useEffect(() => {
    goToPosts({ page: "server" });
  }, []);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
      return (
        <Input
          allowClear
          autoFocus
          placeholder="Type text here"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
        />
      );
    },
    filterIcon: () => {
      return (
        <>
          <SearchOutlined />
        </>
      );
    },
  });

  const deleteTable = (id) => {
    const callback = (data) => {
      getServerList();
    };

    const errorCallback = (data) => { };

    REQUESTS.SERVER.DELETE(id, callback, errorCallback);
  };

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "delete":
        showPropsConfirm(item.id);
        break;

      case "edit":
        setEditServer(item);
        setVisible(true);
        break;

      default:
        break;
    }
  };

  const handleSwichClick = (e, item) => {
    const body = {
      id: item.id,
      // is_default: true,
    };
    function callback() {
      getServerList();
    }

    function errorCallback(err) { }

    REQUESTS.SERVER.EDIT_DEFAULT(body, callback, errorCallback);
  };

  const showPropsConfirm = (id) => {
    confirm({
      content: "Are you sure you want to delete?",
      okText: "DELETE",
      okType: "default",
      okButtonProps: {
        disabled: false,
        style: {
          backgroundColor: "#bf4342",
          color: "#fff",
        },
        loading: loading,
      },

      cancelText: "CANCEL",
      onOk() {
        deleteTable(id);
      },

      onCancel() { },
    });
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      align: "center",
      render: (text, record, index) => {
        if (currentPage === 1) {
          return index + 1;
        } else {
          return limit * (currentPage - 1) + index + 1;
        }
      },
    },

    {
      title: `${translation["Name"] || TEXT["Name"]}`,
      dataIndex: "name",
      key: "name",
      align: "center",
      ...getColumnSearchProps("name"),
    },

    {
      title: `${translation["Host"] || TEXT["Host"]}`,
      dataIndex: "host",
      key: "host",
      align: "center",
      ...getColumnSearchProps(),
    },

    {
      title: `${translation["External API link"] || TEXT["External API link"]}`,
      dataIndex: "external_api",
      key: "external_api",
      align: "center",
      width: 200,
      render: (text) => {
        if (text) {
          return (
            <Typography.Paragraph
              ellipsis={true}
              // style={{ margin: 0, width: 50 }}
            >
              {text}
            </Typography.Paragraph>
          )
        } else {
          return "N/A";
        }
      }
    },

    {
      title: `${translation["Date"] || TEXT["Date"]}`,
      dataIndex: "createdAt",
      align: "center",
      sorter: true,
      render: (record) => {
        return getMyDate(record);
      },
      filterDropdown: ({ }) => (
        <div
          style={{
            padding: 8,
          }}
        >
          <RangePicker
            size="small"
            onChange={(e, dateString) => setDate(dateString)}
            renderExtraFooter={() => "extra footer"}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <CalendarOutlined
          style={{
            color: JSON.parse(searchParams.get("between"))?.createdAt
              ? "#1890ff"
              : undefined,
          }}
        />
      ),
    },

    {
      title: `${translation["Default"] || TEXT["Default"]}`,
      dataIndex: "is_default",
      key: "is_default",
      align: "center",
      render: (text, record, index) => {
        // return record.is_default === false ? (
        return (
          <Switch
            checked={record.is_default === false ? false : true}
            disabled={record.is_default === false ? false : true}
            style={{
              backgroundColor: record.is_default ? "#1890ff" : undefined,
            }}
            onClick={(e) => handleSwichClick(e, record)}
          />
        );
      },
    },

    {
      title: "",
      dataIndex: "action",
      width: "60px",
      align: "center",
      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          buttons={[
            { key: "edit", text: "edit", icon: <EditOutlined /> },
            {
              key: "delete",
              text: "delete",
              icon: <DeleteOutlined />,

              disabled: server[index].is_default ? true : false,
            },
          ]}
        />
      ),
    },
  ];

  const getServerList = () => {
    setLoading(true);
    const query = {
      page: currentPage,
      limit,
      pagination: 1,
      sort: JSON.stringify(sort),
      search: {},
    };

    if (search.host) {
      query.search["host"] = search.host[0];
    }

    if (search.name) {
      query.search["name"] = search.name[0];
    }

    if (query.search) {
      query.search = JSON.stringify(query.search);
    }

    if (date && date[0]) {
      let to = new Date(date[1]);
      to.setDate(to.getDate() + 1);
      query.between = JSON.stringify({
        createdAt: {
          from: new Date(date[0]),
          to: new Date(to),
        },
      });
    }

    REQUESTS.SERVER.GET(query, (data) => {
      setTotal(data.count);
      // setLimit(data.limit);
      setLoading(false);
      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
      setServer(data.rows);
      if (!data.rows == []) {
        setEmpty(false);
      }
    });
  };

  useEffect(() => {
    return () => {
      setEmpty(true);
    };
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    setSearch(filters);

    if (Object.values(filters)[0]) {
      //   setTitleSearch(Object.values(filters)[0].join(""));
    } else {
      //   setTitleSearch(null);
    }

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const onClickAdd = () => {
    setVisible(true);
  };

  const closeDrawer = () => {
    setVisible(false);
    setEditServer(null);
  };

  useEffect(() => {
    let timout = setTimeout(() => {
      getServerList();
    }, 500);
    return () => {
      clearTimeout(timout);
    };
  }, [search, currentPage, limit, date, sort]);

  const followAddElement = {
    setCurrentPage: setCurrentPage,
    setSearch: setSearch,
    setSort: setSort,
    setLimit: setLimit,
    setTotal: setTotal,
    setDate: setDate,
  };

  return (
    <div className={classes["server_list_table"]}>
      <div className={classes["server_list_title"]}>
        <Button type="primary" icon={<PlusOutlined />} onClick={onClickAdd} />
      </div>

      <Table
        loading={loading}
        rowKey="id"
        columns={columns}
        dataSource={server}
        onChange={handleTableChange}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
        scroll={{ x: "max-content" }}
        size="small"
      />

      <ServerDrawer
        visible={visible}
        onClose={closeDrawer}
        current={editServer}
        getData={getServerList}
        translation={translation}
        followAddElement={followAddElement}
      />
    </div>
  );
}

export default Server;
