import REQUESTS from "../../api/requests";
import { useEffect, useState, useContext } from "react";
import { Table, Button, Input, Modal, DatePicker, Switch, Popover } from "antd";
import { emptyContext } from "../../context_empty/context_empty";

import { useSelector } from "react-redux";
import { selectTranslation } from "../../features/Translation/TranslationSlice";
import getMyDate from "../../components/getMyDate";
import TEXT from "../../config/text";
import {
  PlusOutlined,
  SearchOutlined,
  CalendarOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import CouponsDrawer from "./components/CouponsDrawer";
import InitialParams from "../../components/InitialParams";
import showPropsConfirm from "../../components/showPropsConfirm";
import ActionButton from "../../components/ActionButton";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { selectwidgets } from "../../features/widgets/selector";

import { CSVLink, CSVDownload } from "react-csv";

import classes from "./index.module.scss";

const { RangePicker } = DatePicker;
const { confirm } = Modal;

function Coupons() {
  const [empty, setEmpty] = useContext(emptyContext);
  const [searchParams, setsearchParams] = useSearchParams();
  const [total, setTotal] = useState();
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [edicoupons, setEdicoupons] = useState(null);
  const [date, setDate] = useState("");
  const [coupons, setCoupons] = useState([]);
  const [sort, setSort] = useState(["id", "DESC"]);
  const [search, setSearch] = useState({
    comment: "",
  });
  const [csvFile, setCsvFile] = useState("");

  InitialParams(setLimit, setCurrentPage, setSearch, setSort);
  const widgets = useSelector(selectwidgets);

  const navigate = useNavigate();
  const goToPosts = (params) =>
    navigate({
      search: `?${createSearchParams(params)}`,
    });

  const translation = useSelector(selectTranslation);
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
      return (
        <Input
          allowClear
          autoFocus
          placeholder="Type text here"
          value={search[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
        />
      );
    },
    filterIcon: () => {
      return <SearchOutlined />;
    },
  });

  const deleteCoupon = (id) => {
    const callback = (data) => {
      getCoupons();
    };

    const errorCallback = (data) => {};

    REQUESTS.COUPONS.DELETE(id, callback, errorCallback);
  };

  useEffect(() => {
    if (!visible) {
      setCsvFile("");
    }
  }, [visible]);

  const columns = [
    {
      title: "#",
      align: "center",
      dataIndex: "id",
      render: (text, record, index) => {
        if (currentPage === 1) {
          return index + 1;
        } else {
          return limit * (currentPage - 1) + index + 1;
        }
      },
    },

    {
      title: `${translation["Mac"] || TEXT["Mac"]}`,
      dataIndex: "device",
      key: `mac`,
      align: "center",
      render: (record) => {
        if (record) {
          return record.mac;
        } else {
          return "-";
        }
      },
      ...getColumnSearchProps(),
    },

    {
      title: `${translation["Code"] || TEXT["Code"]}`,
      dataIndex: "code",
      key: "code",
      align: "center",
      ...getColumnSearchProps(),
    },

    {
      title: `${translation["Type"] || TEXT["Type"]}`,
      dataIndex: "type",
      key: "type",
      align: "center",
      filters: [
        {
          text: "Monthly",
          value: "monthly",
        },
        {
          text: "Yearly",
          value: "yearly",
        },
      ],
    },

    {
      title: `${translation["Description"] || TEXT["Description"]}`,
      dataIndex: "comment",
      with: "80px",
      align: "center",
      render: (record, text, index) => {
        if (record) {
          const content = record;
          return (
            <Popover content={content}>
              <div className={classes["ref-link"]}>{record}</div>
            </Popover>
          );
        }
      },
      ...getColumnSearchProps(),
    },

    {
      title: `${translation["Status"] || TEXT["Status"]}`,
      dataIndex: "status",
      key: `status`,
      align: "center",
      filters: [
        {
          text: "Available",
          value: "Available",
        },
        {
          text: "Activated",
          value: "Activated",
        },
      ],
    },

    {
      title: ` ${translation["Date"] || TEXT["Date"]}`,
      align: "center",
      dataIndex: "createdAt",
      align: "center",
      sorter: true,
      render: (record) => {
        return getMyDate(record);
      },
      filterDropdown: ({}) => (
        <div
          style={{
            padding: 8,
          }}
        >
          <RangePicker
            size="small"
            onChange={(e, dateString) => setDate(dateString)}
            renderExtraFooter={() => "extra footer"}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <CalendarOutlined
          style={{
            color: JSON.parse(searchParams.get("between"))?.createdAt
              ? "#1890ff"
              : undefined,
          }}
        />
      ),
    },

    {
      title: "",
      width: "50px",
      align: "center",
      render: (row, record, index) => (
        <ActionButton
          handleMenuClick={(e) =>
            showPropsConfirm(
              record.id,
              deleteCoupon,
              loading,
              `${translation["DELETE"] || TEXT["DELETE"]}`,
              "#bf4342",
              `${
                translation["Are you sure you want to delete?"] ||
                TEXT["Are you sure you want to delete?"]
              }`,
              `${translation["CANCEL"] || TEXT["CANCEL"]}`
            )
          }
          text={""}
          index={index}
          icon={<DeleteOutlined />}
          disabled={row.device_id ? true : false}
        />
      ),
    },
  ];

  const getCoupons = () => {
    setLoading(true);
    const query = {
      page: currentPage,
      limit,
      pagination: 1,
      sort: JSON.stringify(sort),
      search: {},
    };

    if (search.code) {
      query.search["code"] = search.code[0];
    }

    if (search.type) {
      query.search["type"] = search.type[0];
    }

    if (search.comment) {
      query.search["comment"] = search.comment[0];
    }

    if (search.status) {
      query.search["status"] = search.status[0];
    }

    if (query.search) {
      query.search = JSON.stringify(query.search);
    }

    if (date && date[0]) {
      let to = new Date(date[1]);
      to.setDate(to.getDate() + 1);
      query.between = JSON.stringify({
        createdAt: {
          from: new Date(date[0]),
          to: new Date(to),
        },
      });
    }

    goToPosts(query);

    REQUESTS.COUPONS.GET(query, (data) => {
      setTotal(data.count);
      // setLimit(data.limit);
      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
      setLoading(false);
      setCoupons(data.rows);

      if (!data.rows == []) {
        setEmpty(false);
      }
    });
  };

  useEffect(() => {
    return () => {
      setEmpty(true);
    };
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    setSearch(filters);

    if (Object.values(filters)[0]) {
      //   setTitleSearch(Object.values(filters)[0].join(""));
    } else {
      //   setTitleSearch(null);
    }

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const onClickAdd = () => {
    setVisible(true);
  };

  const closeDrawer = () => {
    setVisible(false);
    setEdicoupons(null);
  };

  useEffect(() => {
    let timout = setTimeout(() => {
      getCoupons();
    }, 500);
    return () => {
      clearTimeout(timout);
    };
  }, [search, currentPage, limit, sort, date]);

  const followAddElement = {
    setCurrentPage: setCurrentPage,
    setSearch: setSearch,
    setSort: setSort,
    setCurrentPage: setCurrentPage,
    setLimit: setLimit,
    setTotal: setTotal,
    setDate: setDate,
  };

  const setCsv = (data) => {
    setCsvFile(data);
  };

  return (
    <div className={classes["coupons_list_table"]}>
      {((widgets && !widgets.coupons) ||
        (widgets && widgets.coupons &&
          widgets.coupons.enabled &&
          widgets.coupons.enabled === "false")) && (
        <>
          <div className="pro-widget-warning-text">
            To use this feature you need to upgrade to Pro Version
            <br />
            Please contact us for more details.
          </div>
          <div className="pro-widget-tab-layer"></div>
        </>
      )}

      <div className={classes["coupons_list_title"]}>
        <p className={classes["coupons_title-text"]}>
          {translation["Gift Codes"] || TEXT["Gift Codes"]}
        </p>
        <div className={classes["coupon_csv_contenair"]}>
          {csvFile ? <CSVDownload data={csvFile} target="_blank" /> : ""}
          <CSVLink data={coupons} separator={";"}>
            <Button type="primary">Download CSV</Button>
          </CSVLink>

          <Button
            className={classes["coupons_add"]}
            type="primary"
            icon={<PlusOutlined />}
            onClick={onClickAdd}
          />
        </div>
      </div>

      <Table
        loading={loading}
        rowKey="id"
        columns={columns}
        dataSource={coupons}
        onChange={handleTableChange}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
          // defaultPageSize: 1,
        }}
        scroll={{ x: "max-content" }}
      />

      <CouponsDrawer
        visible={visible}
        onClose={closeDrawer}
        current={edicoupons}
        getData={getCoupons}
        translation={translation}
        followAddElement={followAddElement}
        setCsv={setCsv}
      />
    </div>
  );
}

export default Coupons;
